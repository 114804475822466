<template>
    <app-dialog class="dialog-add-api-key"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h3>Add New API Key</h3>
        </template>

        <template #form>
            <app-error v-model="errors.show" :message="errors.message"></app-error>

            <app-textarea
                v-model.trim="api_key.description"
                label="Key Description"
                :required="true"
                :error="errors.fields.description"

                @change="showNextError('description')"
            ></app-textarea>

            <app-input
                v-model="api_key.api_key"
                label="API Key"
                disabled
            ></app-input>

            <app-checkbox-group
                v-if="with_teams_roles"

                v-model="api_key.roles"
                label="Team roles"

                :options="filtered_teams_roles"

                key-value="Name"
                key-title="Title"
            ></app-checkbox-group>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing">Save</button>
            <button class="btn btn-cancel" @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
/**
 * @todo
 * Make one common component for create/edit an API key
 */
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckboxGroup from '@/components/app-checkbox-group'

import APIKeyValidator from '@/validators/api-key-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true },
        maxWidth: { type: [ Number, String ], default: 1000 },
    },

    components: {
        appDialog,

        appError,
        appInput,
        appTextarea,
        appCheckboxGroup,
    },

    data() {
        return {
            api_key: {
                description: '',
                api_key: 'Your API Key will be generated when you save',
                roles: [],
            },

            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',
                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getTeamsRoles()
        },

        getTeamsRoles() {
            this.loading = true

            const stop_loading = () => { this.loading = false }

            this.$store.dispatch('getTeamsRoles').then(stop_loading).catch(stop_loading)
        },

        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        validation() {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',
                fields: {},
            }

            const values = {
                description: {
                    value: this.api_key.description,
                    message: 'Please, enter description',
                },
            }

            for (const key in values) {
                if (APIKeyValidator.isRuleExists(key)) {
                    if (APIKeyValidator.isInvalid(key, values[key].value, values[key].message)) {
                        this.errors.fields[ key ] = APIKeyValidator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },

        confirm() {
            if (this.validation()) {
                this.processing = true

                const req = {
                    SPID:        this.current_spid,
                    Description: this.api_key.description,
                    Roles:       this.api_key.roles,
                }

                this.$store.dispatch('api_auth/AddAPIKey', req).then(response => {
                    this.$emit('api-key-added', response)
                    this.processing = false
                    this.close()
                }).catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)
    
                    this.processing = false
                })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'teams_roles',
        ]),

        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        filtered_teams_roles() {
            const filtered_teams_roles = JSON.parse(JSON.stringify(this.teams_roles))

            delete filtered_teams_roles.SP_ADMIN

            return filtered_teams_roles
        },

        with_teams_roles() {
            return this.filtered_teams_roles && Object.keys(this.filtered_teams_roles).length > 0
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-add-api-key {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 48px 80px;

            h3 {
                margin-bottom: 40px;
            }

            .app-error {
                margin-bottom: 24px;
            }

            .app-textarea {
                height: 144px;
            }

            .app-checkbox-group {
                .options {
                    .app-checkbox {
                        width: 155px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-add-api-key  {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;

                h3 {
                    margin-bottom: 24px;
                }

                .app-checkbox-group {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>

<template>
    <div class="page-api-access container">
        <div class="row">
            <div class="col-8 col-tab-12">
                <app-error v-model="errors.show" :message="errors.message"></app-error>

                <app-table
                    class="api-access-table"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :cols="cols"
                    :rows="api_keys"

                    :loading="loading || processing"

                    @edit-api-key="onAPIKeyEdit"
                    @remove-api-key="onAPIKeyRemove"
                />

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>

            <div class="col-4 col-tab-12">
                <app-adding-banner
                    header="Add New API Key"
                    description="Add API Keys to access our API from your existing systems"

                    image="api-key"

                    action="create"

                    @create="dialogs.create.show = true"
                />
            </div>
        </div>

        <dialog-add-api-key
            v-if="dialogs.create.show"
            v-model="dialogs.create.show"

            @api-key-added="onAPIKeyAdded"
        ></dialog-add-api-key>

        <dialog-edit-api-key
            v-if="dialogs.edit.show"
            v-model="dialogs.edit.show"

            :api-key="dialogs.edit.api_key"

            @api-key-saved="onAPIKeySaved"
        ></dialog-edit-api-key>

        <dialog-remove-api-key
            v-if="dialogs.remove.show"
            v-model="dialogs.remove.show"

            :api-key="dialogs.remove.api_key"

            @api-key-removed="onAPIKeyRemoved"
        ></dialog-remove-api-key>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appAddingBanner from '@/components/app-adding-banner'

import dialogAddAPIKey from './dialogs/dialog-add-api-key'
import dialogEditAPIKey from './dialogs/dialog-edit-api-key'
import dialogRemoveAPIKey from './dialogs/dialog-remove-api-key'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appError,
        appTable,
        appPagination,

        appAddingBanner,

        'dialog-add-api-key': dialogAddAPIKey,
        'dialog-edit-api-key': dialogEditAPIKey,
        'dialog-remove-api-key': dialogRemoveAPIKey,
    },

    data() {
        return {
            api_keys_list: [],

            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'CreatedAtNanos', title: 'Created at' },
            ],

            cols: [
                { key: 'description', title: 'Key Description',                                       },
                { key: 'roles',       title: 'Roles',           type: 'tags',   class: 'bilinear',    },
                { key: 'created',     title: 'Created at',      sort: { field: 'CreatedAtNanos' },    },

                { key: 'edit',        action: 'edit-api-key',   icon: 'edit',   behavior: 'detached', },
                { key: 'remove',      action: 'remove-api-key', icon: 'remove', behavior: 'detached', },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                create: {
                    show: false,
                },

                edit: {
                    show: false,
                    api_key: null,
                },

                remove: {
                    show: false,
                    api_key: null,
                },
            },

            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getTeamsRoles()
            this.getAPIKeys()
        },

        getTeamsRoles() {
            this.processing = true

            const stop_loading = () => { this.processing = false }

            this.$store.dispatch('getTeamsRoles')
                .then(stop_loading)
                .catch(stop_loading)
        },

        getAPIKeys(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...filter,
            }

            const params = {
                'SPID':                     this.current_spid,
                'IncludeInactive':          true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_auth/GetAPIKeysPaginated', params)
                .then(response => {
                    this.api_keys_list = response.APIKeys

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.api_keys_list = []

                    this.loading = false
                })
        },

        onAPIKeyAdded() {
            this.getAPIKeys(this.pagination.page)
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getAPIKeys()
        },

        onPageChange(page) {
            this.getAPIKeys(page)
        },

        onAPIKeyEdit({ uuid }) {
            this.dialogs.edit.api_key = this.api_keys_list.find(api_key => api_key.UUID == uuid)
            this.dialogs.edit.show = true
        },

        onAPIKeySaved() {
            this.dialogs.edit.api_key = null
            this.dialogs.edit.show = false

            this.getAPIKeys(this.pagination.page)
        },

        onAPIKeyRemove({ uuid }) {
            this.dialogs.remove.api_key = this.api_keys_list.find(api_key => api_key.UUID == uuid)
            this.dialogs.remove.show = true
        },

        onAPIKeyRemoved() {
            this.dialogs.remove.api_key = null
            this.dialogs.remove.show = false

            this.getAPIKeys(this.pagination.page)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'teams_roles',
        ]),

        api_keys() {
            const api_keys = []
            const teams_roles = { ...this.teams_roles }

            const are_teams_roles_loaded = Object.keys(teams_roles).length > 0

            for (let i = 0, list_len = this.api_keys_list.length; i < list_len; i++) {
                const api_key = this.api_keys_list[i]
                const roles = []

                if (are_teams_roles_loaded) {
                    for (let j = 0, roles_len = api_key.Roles.length; j < roles_len; j++) {
                        if (api_key.Roles[j] in teams_roles) {
                            roles.push(teams_roles[api_key.Roles[j]].Title)
                        }
                    }
                }

                api_keys.push({
                    uuid:        api_key.UUID,
                    description: api_key.Description ? api_key.Description : '?',
                    roles:       roles,
                    created:     formatDateNano(api_key.CreatedAtNanos, 'YYYY-MM-DD HH:mm:ss'),
                })
            }

            return api_keys
        },
    },
}
</script>

<style lang="scss">
.page-api-access {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .api-access-table {
        @include table-cols-width((300px, 300px, 180px, 48px, 48px), true, 2);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-api-access {
        .api-access-table {
            @include table-cols-width((300px, 300px, 150px, 48px, 48px), true, 2);
        }

        .app-adding-banner {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-api-access {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .api-access-table {
            @include table-cols-width-mobile((75px, 180px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-adding-banner {
            margin-top: 15px;
        }
    }
}
</style>